import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_create_dashboard_alert,
  gql_get_vendor_dashboard_alert,
  gql_update_dashboard_alert,
} from "../../../redux/actions/vendorActions/vendorDashboardAction";
import SetAlertPopper from "./SetAlertPopper";
import LowHighChipVendorRisk from "./LowHighChipVendorRisk";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },
  dialogTitle: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  dialogTitle1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  typoCont: { width: "100%" },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
  // grid3: { display: "flex", justifyContent: "flex-end" },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
}));

function RiskPopup({
  popup,
  setPopup,
  alertName,
  modalText,
  setDefaultAlertValue,
  setAlertPopper,
  handleShowRisk,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state);

  const [input, setInput] = useState(null);

  const [materialityThreshold, setMaterialityThreshold] = useState(null);
  const [performanceThreshold, setPerformanceThreshold] = useState(null);
  const [categorizationThreshold, setCategorizationThreshold] = useState(null);

  // useEffect(() => {
  //   if (modalText?.buttonText === "Update Alert") {
  //     setInput(handleShowRisk?.threshold);
  //   } else {
  //     setInput("");
  //   }
  // }, [modalText]);

  console.log(input);

  const handleThresholdInputs = (event) => {
    const { name, value } = event.target;

    if (
      alertName === "VENDOR_ONBOARDING_VENDOR_MATERIALITY" ||
      alertName === "VENDOR_ONBOARDING_VENDOR_PERFORMANCE" ||
      alertName === "VENDOR_ONBOARDING_VENDOR_CATEGORIZATION"
    ) {
      setInput((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      setInput((prev) => {
        return {
          ...prev,
          [name]: name === "threshold" ? parseFloat(value) : value,
        };
      });
    }
  };

  const handleAlert = async () => {
    if (modalText?.buttonText === "Set Alert") {
      if (alertName === "VENDOR_ONBOARDING") {
        await dispatch(
          gql_create_dashboard_alert({
            emailsToAlert: [login?.email],
            threshold: materialityThreshold?.threshold,
            widgetName: "VENDOR_ONBOARDING_VENDOR_MATERIALITY",
            operator: materialityThreshold?.operator,
          })
        );
        await dispatch(
          gql_create_dashboard_alert({
            emailsToAlert: [login?.email],
            threshold: performanceThreshold?.threshold,
            widgetName: "VENDOR_ONBOARDING_VENDOR_PERFORMANCE",
            operator: performanceThreshold?.operator,
          })
        );
        await dispatch(
          gql_create_dashboard_alert({
            emailsToAlert: [login?.email],
            threshold: categorizationThreshold?.threshold,
            widgetName: "VENDOR_ONBOARDING_VENDOR_CATEGORIZATION",
            operator: categorizationThreshold?.operator,
          })
        );
        setAlertPopper(null);
      } else {
        const createAlertInput = {
          emailsToAlert: [login?.email],
          widgetName: alertName,
          ...input,
        };

        await dispatch(gql_create_dashboard_alert(createAlertInput));
        setAlertPopper(null);
      }

      if (!handleShowRisk?.threshold) {
        setDefaultAlertValue(parseFloat(input?.threshold));
      }
    } else if (modalText?.buttonText === "Update Alert") {
      const updateAlertInput = {
        _id: handleShowRisk?._id,
        ...input,
      };
      await dispatch(gql_update_dashboard_alert(updateAlertInput));

      const getAlertInput = {
        widgetName: [
          "AVERAGE_RISK_BY_VENDORS",
          "AVERAGE_ESG_RISK",
          "AVERAGE_VENDOR_RISK",
          "VENDOR_ONBOARDING_VENDOR_MATERIALITY",
          "VENDOR_ONBOARDING_VENDOR_PERFORMANCE",
          "VENDOR_ONBOARDING_VENDOR_CATEGORIZATION",
        ],
      };
      dispatch(gql_get_vendor_dashboard_alert(getAlertInput));

      setDefaultAlertValue(parseFloat(input?.threshold));
    }

    setPopup(false);
  };

  const handlePauseAlert = async () => {
    let updateAlertInput;
    if (!handleShowRisk?.pause) {
      updateAlertInput = {
        _id: handleShowRisk?._id,
        pause: true,
      };
    } else {
      updateAlertInput = {
        _id: handleShowRisk?._id,
        pause: false,
      };
    }
    await dispatch(gql_update_dashboard_alert(updateAlertInput));
    const getAlertInput = {
      widgetName: [
        "AVERAGE_RISK_BY_VENDORS",
        "AVERAGE_ESG_RISK",
        "AVERAGE_VENDOR_RISK",
        "VENDOR_ONBOARDING_VENDOR_MATERIALITY",
        "VENDOR_ONBOARDING_VENDOR_PERFORMANCE",
        "VENDOR_ONBOARDING_VENDOR_CATEGORIZATION",
      ],
    };
    dispatch(gql_get_vendor_dashboard_alert(getAlertInput));
    setPopup(false);
  };

  const handleMaterialityChange = (event) => {
    const { name, value } = event.target;
    setMaterialityThreshold((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handlePerformanceChange = (event) => {
    const { name, value } = event.target;

    setPerformanceThreshold((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleCategorizationChange = (event) => {
    const { name, value } = event.target;

    setCategorizationThreshold((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Dialog
      open={popup}
      onClose={() => {
        setPopup(false);
        setAlertPopper(null);
        setInput(null);
        setMaterialityThreshold(null);
        setPerformanceThreshold(null);
        setCategorizationThreshold(null);
      }}
      fullWidth="true"
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid
          container
          justifyContent="center"
          className={classes.dialogTitle1}
        >
          <Grid item xs={10}>
            <Typography className={classes.dialogTitle}>
              {modalText?.heading}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="flex-start"
          // style={{}}
        >
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#9A9A9A",
              marginBottom: "1em",
            }}
          >
            {modalText?.message}
          </Typography>
          {alertName === "AVERAGE_RISK_BY_VENDORS" && (
            <>
              <Grid item className={classes.typoCont}>
                {/* <label>Threshold value</label> */}
                <Box
                  style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                >
                  <CustomTextfield
                    label="Value"
                    fullWidth
                    size="small"
                    variant="outlined"
                    helperText=""
                    disabled={true}
                  />

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Condition</InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label"
                      name="operator"
                      value={input?.operator || ""}
                      onChange={handleThresholdInputs}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{
                        classes: { paper: classes.selectMenu },
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[
                        { name: "=", value: "=" },
                        { name: ">", value: ">" },
                        { name: "<", value: "<" },
                      ].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CustomTextfield
                    label="Threshold"
                    name="threshold"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={handleThresholdInputs}
                  />
                </Box>
              </Grid>
            </>
          )}

          {(alertName === "AVERAGE_VENDOR_RISK" ||
            alertName === "AVERAGE_ESG_RISK") && (
            <>
              <Grid item className={classes.typoCont}>
                {/* <label>Threshold value</label> */}
                <Box
                  style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                >
                  <CustomTextfield
                    label="Value"
                    fullWidth
                    size="small"
                    variant="outlined"
                    helperText=""
                    disabled={true}
                  />

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Condition</InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label"
                      name="operator"
                      value={input?.operator || ""}
                      onChange={handleThresholdInputs}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{
                        classes: { paper: classes.selectMenu },
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[{ name: "=", value: "=" }].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Threshold</InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label"
                      name="threshold"
                      value={input?.threshold || ""}
                      onChange={handleThresholdInputs}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{
                        classes: { paper: classes.selectMenu },
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[
                        { name: "Low", value: 0.1 },
                        { name: "Medium", value: 1.5 },
                        { name: "High", value: 3.5 },
                      ].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </>
          )}

          {alertName === "VENDOR_ONBOARDING" && (
            <>
              <Grid item className={classes.typoCont}>
                <label>Vendor Materiality</label>
                <Box
                  style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                >
                  <CustomTextfield
                    label="Value"
                    fullWidth
                    size="small"
                    variant="outlined"
                    helperText=""
                    disabled={true}
                  />

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Condition</InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label"
                      name="operator"
                      value={materialityThreshold?.operator || ""}
                      onChange={handleMaterialityChange}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{
                        classes: { paper: classes.selectMenu },
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[{ name: "=", value: "=" }].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label-materiality">
                      Threshold
                    </InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label-materiality"
                      name="threshold"
                      value={materialityThreshold?.threshold}
                      onChange={handleMaterialityChange}
                      variant="outlined"
                      size="small"
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[
                        { name: "Material", value: "Material" },
                        { name: "Non Material", value: "Non Material" },
                      ].map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item className={classes.typoCont}>
                <label>Vendor Performance</label>
                <Box
                  style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                >
                  <CustomTextfield
                    label="Value"
                    fullWidth
                    size="small"
                    variant="outlined"
                    helperText=""
                    disabled={true}
                  />

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Condition</InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label"
                      name="operator"
                      value={performanceThreshold?.operator || ""}
                      onChange={handlePerformanceChange}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{
                        classes: { paper: classes.selectMenu },
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[{ name: "=", value: "=" }].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label-performance">
                      Threshold
                    </InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label-performance"
                      name="threshold"
                      value={performanceThreshold?.threshold}
                      onChange={handlePerformanceChange}
                      variant="outlined"
                      size="small"
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[
                        { name: "Approved", value: "Approved" },
                        {
                          name: "Approved with deviation",
                          value: "Approved with deviation",
                        },
                        { name: "Rejected", value: "Rejected" },
                      ].map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item className={classes.typoCont}>
                <label>Vendor Categorization</label>
                <Box
                  style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                >
                  <CustomTextfield
                    label="Value"
                    fullWidth
                    size="small"
                    variant="outlined"
                    helperText=""
                    disabled={true}
                  />

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Condition</InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label"
                      name="operator"
                      value={categorizationThreshold?.operator || ""}
                      onChange={handleCategorizationChange}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{
                        classes: { paper: classes.selectMenu },
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[{ name: "=", value: "=" }].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label-categorization">
                      Threshold
                    </InputLabel>
                    <Select
                      labelWidth={80}
                      fullWidth
                      labelId="select-label-categorization"
                      name="threshold"
                      value={categorizationThreshold?.threshold}
                      onChange={handleCategorizationChange}
                      variant="outlined"
                      size="small"
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[
                        { name: "Low", value: "Low" },
                        { name: "Medium", value: "Medium" },
                        { name: "High", value: "High" },
                      ].map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </>
          )}

          {(alertName === "VENDOR_ONBOARDING_VENDOR_MATERIALITY" ||
            alertName === "VENDOR_ONBOARDING_VENDOR_PERFORMANCE" ||
            alertName === "VENDOR_ONBOARDING_VENDOR_CATEGORIZATION") && (
            <Grid item className={classes.typoCont}>
              <label>Vendor Materiality</label>
              <Box style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
                <CustomTextfield
                  label="Value"
                  fullWidth
                  size="small"
                  variant="outlined"
                  helperText=""
                  disabled={true}
                />

                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="select-label">Condition</InputLabel>
                  <Select
                    labelWidth={80}
                    fullWidth
                    labelId="select-label"
                    name="operator"
                    value={input?.operator || ""}
                    onChange={handleThresholdInputs}
                    variant="outlined"
                    size="small"
                    // disabled={isFrom === "createVendor" ? false : !edit}
                    MenuProps={{
                      classes: { paper: classes.selectMenu },
                    }}
                    style={{ height: "40px" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {[{ name: "=", value: "=" }].map((option, i) => (
                      <MenuItem
                        key={i}
                        // style={{ width: 800 }}
                        value={option?.value}
                        className={classes.menuItem}
                      >
                        {option?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="select-label-materiality">
                    Threshold
                  </InputLabel>
                  <Select
                    labelWidth={80}
                    fullWidth
                    labelId="select-label-materiality"
                    name="threshold"
                    value={input?.threshold}
                    onChange={handleThresholdInputs}
                    variant="outlined"
                    size="small"
                    style={{ height: "40px" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {(alertName === "VENDOR_ONBOARDING_VENDOR_MATERIALITY"
                      ? [
                          { name: "Material", value: "Material" },
                          { name: "Non Material", value: "Non Material" },
                        ]
                      : alertName === "VENDOR_ONBOARDING_VENDOR_PERFORMANCE"
                      ? [
                          { name: "Approved", value: "Approved" },
                          {
                            name: "Approved with deviation",
                            value: "Approved with deviation",
                          },
                          { name: "Rejected", value: "Rejected" },
                        ]
                      : [
                          { name: "Low", value: "Low" },
                          { name: "Medium", value: "Medium" },
                          { name: "High", value: "High" },
                        ]
                    ).map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2} className={classes.grid7}>
          <Grid item xs={6} md={6} className={classes.grid3}>
            {modalText?.buttonText === "Update Alert" && (
              <CustomButton
                style={{ color: "#F44336", borderColor: "#F44336" }}
                variant="outlined"
                className={classes.CustomButton3}
                onClick={handlePauseAlert}
              >
                {handleShowRisk?.pause ? "Resume" : "Pause"}
              </CustomButton>
            )}
          </Grid>
          <Grid item xs={6} md={6} className={classes.grid3}>
            <CustomButton
              color="primary"
              className={classes.CustomButton3}
              onClick={() => {
                setPopup(false);
                setAlertPopper(null);
              }}
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              className={classes.CustomButton3}
              onClick={handleAlert}
              // disabled={
              //   modalText?.buttonText === "Update Alert" &&
              //   dashboardAlert?.threshold
              //     ? false
              //     : true
              // }
            >
              {modalText?.buttonText}
            </CustomButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default RiskPopup;
